module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":false,"icon":"/favicon.icon"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"static/favicon-32x32.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"5053b235269b3edb18be6d259f369a24"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/runner/work/filmpositive-gatsby/filmpositive-gatsby/locales","languages":["en","hu"],"defaultLanguage":"hu","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false,"lowerCaseLng":true},"pages":[{"matchPath":"/:lang?/commercials/:uid","getLanguageFromPath":true,"excludeLanguages":["hu","en"]},{"matchPath":"/:lang?/movies/:uid","getLanguageFromPath":true,"excludeLanguages":["hu","en"]},{"matchPath":"/:lang?/reklamok/:uid","getLanguageFromPath":true,"excludeLanguages":["hu","en"]},{"matchPath":"/:lang?/filmek/:uid","getLanguageFromPath":true,"excludeLanguages":["hu","en"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
